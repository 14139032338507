jQuery(document).ready(function ($) {
  // Resize and move elements
  function khoozhResponsiveElements() {
    // Desktop width
    if ($(document).width() < 960) {
      // $('#order-review-wrapper').insertBefore($('.woocommerce-checkout-payment').prev());
    } else {
      // if (typeof wooPinCheckoutReview === "function") {
      //   wooPinCheckoutReview();
      // }
      // $('#order-review-wrapper').appendTo($('#customer_details .col-2'));
    }
  }
  khoozhResponsiveElements();
  $(window).resize(khoozhResponsiveElements);

  // init controller
  var controller = new ScrollMagic.Controller();

  // Create scene for every slide
  // Fade In elements
  var hiddenElements = $();
  // ".section-title"
  hiddenElements.addClass("hidden");
  hiddenElements.each(function () {
    var myScene = new ScrollMagic.Scene({
      triggerElement: this,
      triggerHook: "onEnter",
      offset: 120,
    })
      .setClassToggle(this, "shown")
      .addTo(controller);
  });

  // Fade Right elements
  var fadeRight = $(".cols-2 > *:nth-child(odd)");
  fadeRight.addClass("fade-right-before");
  fadeRight.each(function () {
    var myScene = new ScrollMagic.Scene({
      triggerElement: this,
      triggerHook: "onEnter",
      offset: 120,
    })
      .setClassToggle(this, "fade-right")
      .addTo(controller);
  });

  // Fade Left elements
  var fadeLeft = $(".cols-2 > *:nth-child(even)");
  fadeLeft.addClass("fade-left-before");
  fadeLeft.each(function () {
    var myScene = new ScrollMagic.Scene({
      triggerElement: this,
      triggerHook: "onEnter",
      offset: 120,
    })
      .setClassToggle(this, "fade-left")
      .addTo(controller);
  });

  // Fade Scale elements
  var fadeScale = $(".feature-image__main");
  fadeScale.addClass("fade-scale-before");
  fadeScale.each(function () {
    var myScene = new ScrollMagic.Scene({
      triggerElement: this,
      triggerHook: "onEnter",
      offset: 300,
    })
      .setClassToggle(this, "fade-scale")
      .addTo(controller);
  });

  // Header scroll
  function headerScroll() {
    if ($(document).scrollTop() > 20) {
      $(".site-header").addClass("header-scroll");
    } else {
      $(".site-header").removeClass("header-scroll");
    }
  }
  headerScroll();
  $(document).on("scroll", headerScroll);

  /*
   * Hero Slider
   */
  // $(".hero-slider").ready(function() {
  //   $('.hero-slider').css({
  //     'opacity': '1',
  //     'visibility': 'visible'
  //   });
  // });

  $("#activate-search").on("click", function () {
    $(".search-form").toggleClass("active");
  });

  $(".menu-social-media-menu-2-container").prepend(
    '<button class="menu-social-media-menu-2-container__acivate"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 15 15" fill="#ffffff"><path d="M9.7 14.6l3.8-7.1c0-.1-3.8-7.1-3.8-7.1C9.5.1 9.3 0 9 0H2.2c-.2 0-.5.1-.6.4-.1.2-.1.5 0 .7l3.5 6.4-3.5 6.4c-.1.1-.1.2-.1.4 0 .1 0 .3.1.4.1.2.4.3.7.3H9c.3 0 .5-.1.7-.4z"/></svg></button>'
  );

  $(".menu-social-media-menu-2-container__acivate").click(function () {
    $(this).parent().toggleClass("active");
  });
});
